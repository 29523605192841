import {
  LOADING_CLIENT,
  FAIL,
  GET_ALL_CLIENTS,
  GET_ONE_CLIENT,
  CREATE_CLIENT,
} from "../actionTypes";
// initialstate
const initialState = {
  allClients: [],
  oneClient: {},
  error: false,
  message: "",
  loading: false,
};

// pure function=> (state, {type,payload})=>
const ClientReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_CLIENT:
      return { ...state, loading: true };
    case FAIL:
      return {
        ...state,
        error: true,
        loading: false,
        message: payload.message,
      };
    case GET_ALL_CLIENTS:
      return {
        ...state,
        error: false,
        loading: false,
        allClients: payload.data,
        message: payload.message,
      };
    case GET_ONE_CLIENT:
      return {
        ...state,
        error: false,
        loading: false,
        oneClient: payload.data,
        message: payload.message,
      };
    case CREATE_CLIENT:
      return {
        ...state,
        error: false,
        loading: false,
        message: payload.message,
      };

    default:
      return state;
  }
};

export default ClientReducer;
