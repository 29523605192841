import { FAIL, LOADING_CLINIC, GET_CLINIC } from "../actionTypes";

const initialState = {
  clinicData: {},
  loading: false,
  error: false,
  message: "",
};

const ClinicReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_CLINIC:
      return { ...state, loading: true };
    case FAIL:
      return {
        ...state,
        error: true,
        loading: false,
        message: payload.message,
      };
    case GET_CLINIC:
      return {
        ...state,
        loading: false,
        error: false,
        clinicData: payload.data,
        message: payload.message,
      };

    default:
      return state;
  }
};

export default ClinicReducer;
