import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { pathIcon } from "../constants/paths";
import { Link, useLocation } from "react-router-dom";

const defaultStyle =
  "h-14 flex flex-row items-center text-main-black gap-4 pr-3 pl-6 rounded-lg border-2 border-white cursor-pointer";
const selectedPathStyle =
  "h-14 flex flex-row items-center text-petoo-blue gap-4 pr-3 pl-6 rounded-lg border-2 border-white cursor-pointer";
const dropDownSelected =
  "h-14 flex flex-row items-center justify-between text-petoo-blue gap-4 pr-3 pl-3 rounded-lg bg-light-blue border-blue border-2 cursor-pointer";
const dropDowndefault =
  "h-14 flex flex-row items-center text-main-black justify-between  gap-4 pr-3 pl-3 rounded-lg border-2 border-white cursor-pointer";
// Handler hook for when Outside click dropdown close

//eslint-disable-next-line
let useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};
// Handler hook for when Outside click dropdown close End Code====>>

const Dropdown = ({ route }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // let domNode = useClickOutside(() => {
  //   setDropdownOpen(false);
  // });
  const { language, translation } = useSelector(
    (state) => state.LanguageReducer
  );
  return (
    <>
      <div className="w-full">
        <div className="w-full relative inline-block ">
          <div
            className={dropdownOpen ? dropDownSelected : dropDowndefault}
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <div className="flex flex-row gap-4 items-center ">
              <span className="text-xl">{pathIcon[route.key]}</span>
              <span className="font-medium">
                {translation["settings"][language]}
              </span>
            </div>
            <span className="text-right">
              <svg
                width={20}
                height={20}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-current"
              >
                <path d="M10 14.25C9.8125 14.25 9.65625 14.1875 9.5 14.0625L2.3125 7C2.03125 6.71875 2.03125 6.28125 2.3125 6C2.59375 5.71875 3.03125 5.71875 3.3125 6L10 12.5312L16.6875 5.9375C16.9688 5.65625 17.4063 5.65625 17.6875 5.9375C17.9687 6.21875 17.9687 6.65625 17.6875 6.9375L10.5 14C10.3437 14.1563 10.1875 14.25 10 14.25Z" />
              </svg>
            </span>
          </div>
          <div
            className={` flex flex-col  absolute left-0 z-40 mt-2 w-full rounded-md bg-white transition-all ${
              dropdownOpen
                ? "top-full opacity-100 visible"
                : "top-[110%] invisible opacity-0"
            }`}
          >
            {route.subMenu.map((option) => (
              <DropdownItem option={option} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dropdown;

const DropdownItem = ({ option }) => {
  const location = useLocation();

  const { language, translation } = useSelector(
    (state) => state.LanguageReducer
  );
  return (
    <Link
      key={option.key}
      to={option.path}
      className={
        location.pathname === option.path ? selectedPathStyle : defaultStyle
      }
    >
      <span className="text-xl">{option.icon}</span>
      <span className="font-medium">{translation[option.key][language]}</span>
    </Link>
  );
};
