import {
  LOADING_STAFF,
  FAIL,
  GET_STAFF,
  GET_STAFF_MEMBER,
  CREATE_STAFF,
  CHANGE_STAFF_PASSWORD,
  UPDATE_PASSWORD,
} from "../actionTypes";
// initialstate
const initialState = {
  staff: [],
  staffMember: {},
  error: false,
  message: "",
  loading: false,
};

// pure function=> (state, {type,payload})=>
const StaffReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_STAFF:
      return { ...state, loading: true };
    case FAIL:
      return {
        ...state,
        error: true,
        loading: false,
        message: payload.message,
      };
    case GET_STAFF:
      return {
        ...state,
        error: false,
        loading: false,
        staff: payload.data,
        message: payload.message,
      };
    case GET_STAFF_MEMBER:
      return {
        ...state,
        error: false,
        loading: false,
        staffMember: payload.data,
        message: payload.message,
      };
    case CREATE_STAFF:
      return {
        ...state,
        error: false,
        loading: false,
        message: payload.message,
      };
    case CHANGE_STAFF_PASSWORD:
      return {
        ...state,
        error: false,
        message: payload.message,
      };
    case UPDATE_PASSWORD:
      return {
        ...state,
        error: false,
        message: payload.message,
      };
    default:
      return state;
  }
};

export default StaffReducer;
