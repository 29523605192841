import Translation from "../../constants/language";
import { CHANGE_LANGUAGE } from "../actionTypes";
// initialstate
const initialState = {
  language: "en",
  translation: Translation,
};

// pure function=> (state, {type,payload})=>
const LanguageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: payload,
      };
    default:
      return state;
  }
};

export default LanguageReducer;
