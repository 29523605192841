//* Auth
export const LOADING = "LOADING";
export const FAIL = "FAIL";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_VIEW = "CHANGE_VIEW";

//* Appointment
export const LOADING_APPOINTMENTS = "LOADING_APPOINTMENTS";
export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const GET_APPOINTMENT = "GET_APPOINTMENT";
export const CREATE_APPOINTMENT = "CREATE_APPOINTMENT";
export const EDIT_APPOINTMENT = "EDIT_APPOINTMENT";
// Staff

export const LOADING_STAFF = "LOADING_STAFF";
export const GET_STAFF = "GET_STAFF";
export const GET_STAFF_MEMBER = "GET_STAFF_MEMBER";
export const CREATE_STAFF = "CREATE_STAFF";
export const EDIT_STAFF = "EDIT_STAFF";
export const ARCHIVE_STAFF = "ARCHIVE_STAFF";
export const CHANGE_STAFF_PASSWORD = "CHANGE_STAFF_PASSWORD";

// Pet
export const LOADING_PET = "LOADING_PET";
export const GET_ONE_PET = "GET_ONE_PET";
export const CREATE_PET = "CREATE_PET";
export const EDIT_PET = "EDIT_PET";
export const ARCHIVE_PET = "ARCHIVE_PET";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";

//Client

export const LOADING_CLIENT = "LOADING_CLIENT";
export const GET_ALL_CLIENTS = "GET_ALL_CLIENTS";
export const GET_ONE_CLIENT = "GET_ONE_CLIENT";
export const CREATE_CLIENT = "CREATE_CLIENT";
export const EDIT_CLIENT = "EDIT_CLIENT";
export const ARCHIVE_CLIENT = "ARCHIVE_CLIENT";

// Vet
export const LOADING_VET = "LOADING_VET";
export const GET_VET = "GET_VET";
export const EDIT_VET = "EDIT_VET";
export const CHANGE_VET_PASSWORD = "CHANGE_VET_PASSWORD";

// Vet
export const LOADING_CLINIC = "LOADING_CLINIC";
export const GET_CLINIC = "GET_CLINIC";
export const EDIT_CLINIC = "EDIT_CLINIC";
