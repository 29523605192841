import {
  GET_VET,
  FAIL,
  LOADING_VET,
  EDIT_VET,
  CHANGE_VET_PASSWORD,
} from "../actionTypes";

const initialState = {
  vetData: {},
  loading: false,
  error: false,
  message: "",
};

const VetReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_VET:
      return { ...state, loading: true };
    case FAIL:
      return {
        ...state,
        error: true,
        loading: false,
        message: payload.message,
      };
    case GET_VET:
      return {
        ...state,
        loading: false,
        error: false,
        vetData: payload.data,
        message: payload.message,
      };
    case EDIT_VET:
      return {
        ...state,
        error: false,
        message: payload.message,
      };
    case CHANGE_VET_PASSWORD:
      return {
        ...state,
        error: false,
        message: payload.message,
      };
    default:
      return state;
  }
};

export default VetReducer;
