const Translation = {
  loading: {
    fr: "Chargement",
    en: "Loading",
  },
  dashboard: {
    fr: "Acceuil",
    en: "Dashboard",
  },
  appointments: {
    fr: "Gestion des rendez-vous",
    en: "Appointments",
  },
  appointments_subtitle: {
    fr: "Optimisez la planification et la gestion de vos rendez-vous.",
    en: "Optimize the scheduling and management of your appointments.",
  },
  clients: {
    fr: "Fiches clients",
    en: "Client records",
  },
  newUser: {
    fr: "Créer un nouvel client",
    en: "Create a new client",
  },
  hour: {
    fr: "Heure",
    en: "Hour",
  },
  accepted: {
    fr: "Accepté",
    en: "Accepted",
  },
  clients_subtitle: {
    fr: "Gardez toutes les informations essentielles de vos clients organisées et accessibles.",
    en: "Keep all essential customer information organized and accessible.",
  },
  reminders: {
    fr: "Rappelles",
    en: "Reminders",
  },
  reminders_subtitle: {
    fr: "Sélectionnez un outil de communication pour interagir avec vos clients.",
    en: "Select a communication tool to interact with your customers.",
  },
  hospitalisation: {
    fr: "Hospitalisation",
    en: "Hospitalisation",
  },
  receipts: {
    fr: "Factures",
    en: "Receipts",
  },
  receipts_subtitle: {
    fr: "Gérez facilement vos transactions et suivez vos finances avec la fonctionnalité de facturation.",
    en: "Easily manage your transactions and track your finances with the billing feature.",
  },
  settings: {
    fr: "Paramètres",
    en: "Settings",
  },
  logout: {
    fr: "Déconnexion",
    en: "Log out",
  },
  validate: {
    fr: "Valider",
    en: "Validate",
  },
  vets: {
    fr: "Vétérinaires",
    en: "Vets",
  },
  owner_fullName: {
    fr: "Nom et prénom du propriétaire",
    en: "Owner full name",
  },
  ownerName: {
    fr: "Nom et prénom",
    en: "Owner full name",
  },
  owner: {
    fr: "Propriétaire",
    en: "Owner",
  },
  name: {
    fr: "Préom",
    en: "First name",
  },
  surname: {
    fr: "Nom",
    en: "Last name",
  },
  animal: {
    fr: "Animale",
    en: "Animal",
  },
  shy: {
    fr: "Timide",
    en: "Shy",
  },
  aggressive: {
    fr: "Aggressif",
    en: "Aggressive",
  },
  calm: {
    fr: "Calme",
    en: "Calm",
  },
  fun: {
    fr: "Amusant",
    en: "Fun",
  },
  yes: {
    fr: "Oui",
    en: "Yes",
  },
  no: {
    fr: "Non",
    en: "No",
  },
  pet_type: {
    fr: "Espèce",
    en: "Specie",
  },
  pet_types: {
    fr: "Espèces",
    en: "Species",
  },
  race: {
    fr: "Race",
    en: "Race",
  },
  races: {
    fr: "Races",
    en: "Races",
  },
  pet_name: {
    fr: "Nom de l’animal",
    en: "Animal name",
  },
  phone: {
    fr: "Num",
    en: "Number",
  },
  date_time: {
    fr: "Date et heure",
    en: "Date & time",
  },
  status: {
    fr: "Statut",
    en: "Status",
  },
  "create-appointment": {
    fr: "Ajouter un rendez-vous",
    en: "Create appointment",
  },
  "edit-appointment": {
    fr: "Modifier rendez-vous",
    en: "Edit appointment",
  },
  "edit-staff": {
    fr: "Modifier le personnel",
    en: "Edit staff",
  },
  confirmed: {
    fr: "Confirmé",
    en: "Confirmed",
  },
  canceled: {
    fr: "Annulé",
    en: "Canceled",
  },
  cancel: {
    fr: "Annuler",
    en: "Cancel",
  },
  generalInformations: {
    fr: "Informations générales",
    en: "General informations",
  },
  email: {
    fr: "Email",
    en: "Email",
  },
  office: {
    fr: "Bureau",
    en: "Office",
  },
  address: {
    fr: "Rue",
    en: "Address",
  },
  full_address: {
    fr: "Adresse complète",
    en: "Full address",
  },
  governorate: {
    fr: "Gouvernorat",
    en: "Governorate ",
  },
  delegation: {
    fr: "Délégation",
    en: "Delegation",
  },
  codePostale: {
    fr: "Code postal",
    en: "Postal code",
  },
  city: {
    fr: "Ville",
    en: "Town",
  },
  choose_hour: {
    fr: "Choisir une heure",
    en: "Choose time",
  },
  "add-client": {
    fr: "Ajouter une nouvelle fiche client",
    en: "Add a new client record",
  },
  addClientButton: {
    fr: "Ajouter un client",
    en: "Add a client",
  },
  modify: {
    fr: "Modifier",
    en: "Modify",
  },
  owner_info: {
    fr: "Informations du propriétaire",
    en: "Owner informations",
  },
  pet_info: {
    fr: "Informations de l’animal",
    en: "Pet details",
  },
  save: {
    fr: "Enregistrer",
    en: "Save",
  },
  petoo_account: {
    fr: "Compte Petoo",
    en: "Petoo account",
  },
  animals: {
    fr: "Animaux",
    en: "Animals",
  },
  add: {
    fr: "Ajouter",
    en: "Add",
  },
  gender: {
    fr: "Sexe",
    en: "Gender",
  },
  character: {
    fr: "Caractère",
    en: "Character",
  },
  birthDate: {
    fr: "Date de naissance",
    en: "Birth date",
  },
  breed: {
    fr: "Race",
    en: "Breed",
  },
  view: {
    fr: "Voir",
    en: "View",
  },
  "add-pet": {
    fr: "Ajouter un animal",
    en: "Add a pet",
  },
  weight: {
    fr: "Poids",
    en: "Weight",
  },
  blood_type: {
    fr: "Group sanguin",
    en: "Blood type",
  },
  castrated: {
    fr: "Castré",
    en: "Castrated",
  },
  passport: {
    fr: "Passeport",
    en: "Passport",
  },
  vaccine_info: {
    fr: "Informations de vaccination",
    en: "Vaccination details",
  },
  chip_info: {
    fr: "Informations de la puce",
    en: "Chip details",
  },
  events: {
    fr: "Événements",
    en: "Events",
  },
  select: {
    fr: "Veuillez sélectionner",
    en: "Select an option",
  },
  import: {
    fr: "Importer",
    en: "Import",
  },
  add_vaccine: {
    fr: "Ajouter un vaccin",
    en: "Add a vaccine",
  },
  vaccine: {
    fr: "Vaccin",
    en: "Vaccine",
  },
  createdAt: {
    fr: "Réalisé le",
    en: "Created at",
  },
  next_vaccine: {
    fr: "Prochain vaccin",
    en: "Next vaccine",
  },
  recovery: {
    fr: "Relance",
    en: "Recovery",
  },
  expected_in: {
    fr: "Prévoir dans",
    en: "Expected in",
  },
  vaccine_history: {
    fr: "Historique des vaccins",
    en: "Vaccine history",
  },
  valid: {
    fr: "Validé",
    en: "Valid",
  },
  reminder: {
    fr: "Rappel",
    en: "Reminder",
  },
  return: {
    fr: "Retour",
    en: "Return",
  },
  chip: {
    fr: "Puce",
    en: "Chip",
  },
  localisation: {
    fr: "Localisation",
    en: "Localisation",
  },
  transponder: {
    fr: "Transpondeur",
    en: "Transponder",
  },
  practitioner: {
    fr: "Praticien",
    en: "Practitioner",
  },
  date: {
    fr: "Date",
    en: "Date",
  },
  categories: {
    fr: "Catégories",
    en: "Categories",
  },
  event_history: {
    fr: "Historiques des événements",
    en: "Event history",
  },
  end_hospitalisation: {
    fr: "Hospitalisation terminée",
    en: "End hospitalisation",
  },
  motive: {
    fr: "Motif",
    en: "Motive",
  },
  placement: {
    fr: "Emplacement",
    en: "Placement",
  },
  notes: {
    fr: "Notes",
    en: "Notes",
  },
  conclusion: {
    fr: "Conclusion",
    en: "Conclusion",
  },
  total_clients: {
    fr: "Total clients",
    en: "Total clients",
  },
  this_month: {
    fr: "Ce mois-ci",
    en: "This month",
  },
  today: {
    fr: "Aujourd’hui",
    en: "Today",
  },
  petoo_visits: {
    fr: "Petoo visites",
    en: "Petoo visits",
  },
  cabin_visits: {
    fr: "Cabinet visites",
    en: "Cabin visits",
  },
  total_visits: {
    fr: "Totale visites",
    en: "Total visits",
  },
  dogs: {
    fr: "Chiens",
    en: "Dogs",
  },
  dog: {
    fr: "Chien",
    en: "Dog",
  },
  cats: {
    fr: "Chats",
    en: "Cats",
  },
  cat: {
    fr: "Chat",
    en: "Cat",
  },
  other: {
    fr: "Autres",
    en: "Other",
  },
  cage: {
    fr: "Chenil",
    en: "Cage",
  },
  exit: {
    fr: "Sortie",
    en: "Exit",
  },
  today_appointments: {
    fr: "Rendez-vous pour aujourd'hui",
    en: "Appointments for today",
  },
  doctors: {
    fr: "Médecines",
    en: "Doctors",
  },
  documents: {
    fr: "Documents",
    en: "Documents",
  },
  staff: {
    fr: "Personnel",
    en: "Staff",
  },
  cabine: {
    fr: "Cabinet",
    en: "Cabine",
  },
  welcome: {
    fr: "Bienvenue",
    en: "Welcome",
  },
  welcome_message: {
    fr: "Voyons ce vous avez pour aujourd'hui",
    en: "Let’s see what you have for today",
  },
  add_cage: {
    fr: "Ajouter un cage",
    en: "Add a cage",
  },
  create_cage: {
    fr: "Créer un cage",
    en: "Create a cage",
  },
  cage_details: {
    fr: "Détails chenil",
    en: "Cage details",
  },
  hospitalisation_subtitle: {
    fr: "Suivez les disponibilités, gérez les réservations et assurez un suivi précis.",
    en: "Track availability, manage bookings and ensure accurate tracking.",
  },
  add_to_cage: {
    fr: "Entrer un animal dans le chenil",
    en: "Add pet to a cage",
  },
  exit_cage: {
    fr: "Sortir du chenil",
    en: "Exit cage",
  },
  age: {
    fr: "Age",
    en: "Age",
  },
  sendSMS: {
    fr: "Envoyer un SMS",
    en: "Send SMS",
  },
  sendEmail: {
    fr: "Envoyer un email",
    en: "Send email",
  },
  send: {
    fr: "Envoyer",
    en: "Send",
  },
  petoo: {
    fr: "Petoo",
    en: "Petoo",
  },
  all: {
    fr: "Autre animal",
    en: "All",
  },
  clientInformation: {
    fr: "Informations du propriétaire",
    en: "Client informations",
  },
  message: {
    fr: "Message",
    en: "Message",
  },
  newMessage: {
    fr: "Nouveau message",
    en: "New message",
  },
  subject: {
    fr: "Objet",
    en: "Subject",
  },
  enable: {
    fr: "Activer",
    en: "Enable",
  },
  activities: {
    fr: "Activités",
    en: "Activities",
  },
  notification: {
    fr: "Notification",
    en: "Notification",
  },
  notification_Petoo: {
    fr: "Notification petoo",
    en: "Notification petoo",
  },
  sms: {
    fr: "SMS",
    en: "SMS",
  },
  appointment: {
    fr: "Rendez-vous",
    en: "Appointment",
  },
  plan: {
    fr: "Planifier",
    en: "Plan",
  },

  planned: {
    fr: "Planifiée",
    en: "Planned",
  },
  ref: {
    fr: "Référence",
    en: "Ref",
  },
  see: {
    fr: "Voir",
    en: "See",
  },
  addInvoice: {
    fr: "Ajouter une facture",
    en: "Add an invoice",
  },
  manageReceipts: {
    fr: "Gérez facilement vos transactions et suivez vos finances avec la fonctionnalité de facturation.",
    en: "Easily manage your transactions and keep track of your finances with billing functionality.",
  },
  total_revenue: {
    fr: "Total revenue",
    en: "Total revenue",
  },

  this_week: {
    fr: "Cette semaine",
    en: "This week",
  },

  total_pets: {
    fr: "Total animaux",
    en: "Total pets",
  },
  settings_subtitle: {
    fr: "Gérez facilement vos préférences et personnalisez votre expérience.",
    en: "Easily manage your preferences and personalize your experience.",
  },
  "my-office": {
    fr: "Mon Cabinet",
    en: "My Office",
  },
  "staff-account": {
    fr: "Compte de personnel",
    en: "Staff account",
  },
  "products-and-prices": {
    fr: "Produits et tarifs",
    en: "Products and prices",
  },
  last_name: {
    fr: "Nom",
    en: "Last name",
  },
  first_name: {
    fr: "Prénom",
    en: "First name",
  },
  password: {
    fr: "Mot de passe",
    en: "Password",
  },
  new_password: {
    fr: "Nouveau mot de passe",
    en: "New password",
  },
  pending: {
    fr: "En attent",
    en: "Pending",
  },
  openning_work: {
    fr: "Heures et jours d'ouverture",
    en: "Opening days and hours",
  },
  working_days: {
    fr: "Jours de travail",
    en: "Working day",
  },
  start_time: {
    fr: "Heure de début",
    en: "Start time",
  },
  end_time: {
    fr: "Heure de la fin",
    en: "End time",
  },
  week_starts_on: {
    fr: "Semaine commence le",
    en: "Week starts on",
  },
  week_ends_on: {
    fr: "Semaine fini le ",
    en: "Week ends on",
  },
  monday: {
    fr: "Lundi",
    en: "Monday",
  },
  tuesday: {
    fr: "Mardi",
    en: "Tuesday",
  },
  wednesday: {
    fr: "Mercredi",
    en: "Wednesday",
  },
  thursday: {
    fr: "Jeudi",
    en: "Thursday",
  },
  friday: {
    fr: "Vendredi",
    en: "Friday",
  },
  saturday: {
    fr: "Samedi",
    en: "Saturday",
  },
  sunday: {
    fr: "Dimanche",
    en: "Sunday",
  },
  change_password: {
    fr: "Changer le mot de passe",
    en: "Change password",
  },
  addStaff: {
    fr: "Ajouter un personnel",
    en: "Add staff",
  },
  delete_account: {
    fr: "Supprimer le compte",
    en: "Delete account",
  },
  delete_file: {
    fr: "Supprimer le fiche",
    en: "Delete file",
  },
  delete_invoice: {
    fr: "Supprimer la facture",
    en: "Delete invoice",
  },
  delete: {
    fr: "Supprimer",
    en: "Delete",
  },
  accessibility: {
    fr: "Accessibilité",
    en: "Accessibility",
  },
  medical_access: {
    fr: " Accès médical",
    en: "Medical access",
  },
  total_access: {
    fr: "Accès total",
    en: "Total access",
  },

  medicines: {
    fr: "Médecines",
    en: "Medicines",
  },
  assistant: {
    fr: "Assistant",
    en: "Assistant",
  },
  doctor: {
    fr: "Docteur",
    en: "Doctor",
  },
  edit: {
    fr: "Modifier",
    en: "Edit",
  },
  add_product: {
    fr: "Ajouter un produit",
    en: "Add Product",
  },
  add_service: {
    fr: "Ajouter un service",
    en: "Add Service",
  },
  products_list: {
    fr: "Liste des produits",
    en: "Products list",
  },
  price: {
    fr: "Prix",
    en: "Price",
  },

  service_list: {
    fr: "Liste des services",
    en: "Services List",
  },
  product: {
    fr: "Produits",
    en: "Products",
  },
  service: {
    fr: "Service",
    en: "Service",
  },
  vaccines: {
    fr: "Vaccins",
    en: "Vaccines",
  },
  species_list: {
    fr: "Liste des espèces",
    en: "Species list",
  },
  purchase_date: {
    fr: "Date achat",
    en: "Purchase date",
  },
  tva: {
    fr: "TVA",
    en: "VAT",
  },
  quantity: {
    fr: "Quantité",
    en: "Quantity",
  },
  total_services: {
    fr: "Total services",
    en: "Total services",
  },
  total: {
    fr: "Total",
    en: "Total",
  },
  male: {
    fr: "Mâle",
    en: "Male",
  },
  female: {
    fr: "Femelle",
    en: "Female",
  },
};
export default Translation;
