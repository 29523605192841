import { CHANGE_VIEW } from "../actionTypes";
// initialstate
const initialState = {
  addPet: "PetDetails",
  hospitalisation: "Cages",
};

// pure function=> (state, {type,payload})=>
const ViewReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_VIEW:
      return {
        ...state,
        [payload.page]: payload.view,
      };
    default:
      return state;
  }
};

export default ViewReducer;
