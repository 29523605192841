// import axios from "axios";
import axios from "axios";
import { LOADING, LOGIN, FAIL, LOGOUT } from "../actionTypes";

export const CheckAuth = (token, navigate) => async (dispatch) => {
  try {
    // dispatch({ type: LOADING });
    const headers = {
      "x-petoo-token": localStorage.getItem("token"),
    };
    await axios.get("https://dev-backend.petoo.app/api/vet/auth", {
      headers,
    });
  } catch (error) {
    dispatch({ type: FAIL, payload: error.response.data });
    navigate("/login");
  }
};

export const LoginVet = (values, navigate) => async (dispatch) => {
  try {
    dispatch({ type: LOADING });

    const res = await axios.post(
      "https://dev-backend.petoo.app/api/vet/login",
      values
    );
    const { message, token } = res.data;

    localStorage.setItem("token", token);
    dispatch({
      type: LOGIN,
      payload: { message, token },
    });
    navigate("/dashboard");
    return res.data;
  } catch (error) {
    dispatch({ type: FAIL, payload: error.response.data });
    return error.response.data;
  }
};

export const Logout = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING });
    dispatch({
      type: LOGOUT,
      payload: { message: "Logged out" },
    });
    return { message: "Logged out" };
  } catch (error) {
    dispatch({ type: FAIL, payload: error.response.data });
  }
};
