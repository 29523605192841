import { LOADING, LOGIN, FAIL, LOGOUT } from "../actionTypes";
// initialstate
const initialState = {
  error: false,
  signedIn: false,
  token: "",
  message: "",
  loading: false,
};

// pure function=> (state, {type,payload})=>
const AuthReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING:
      return { ...state, loading: true };
    case FAIL:
      return {
        ...state,
        error: true,
        loading: false,
        signedIn: false,
        token: "",
        message: payload.message,
      };
    case LOGOUT:
      return {
        ...state,
        error: false,
        loading: false,
        signedIn: false,
        token: "",
        message: payload.message,
      };
    case LOGIN:
      return {
        ...state,
        error: false,
        loading: false,
        signedIn: true,
        token: payload.token,
        message: payload.message,
      };
    default:
      return state;
  }
};

export default AuthReducer;
