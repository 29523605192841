import React from "react";
import { LiaBusinessTimeSolid } from "react-icons/lia";
import { MdDashboard } from "react-icons/md";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { LuFolderClock } from "react-icons/lu";
import { CiHospital1 } from "react-icons/ci";
import { PiCalculatorLight } from "react-icons/pi";
import { IoSettingsOutline } from "react-icons/io5";
import { AiOutlineHome } from "react-icons/ai";
import { HiOutlineUsers } from "react-icons/hi";
import { PiHandCoins } from "react-icons/pi";
import { TfiMenuAlt } from "react-icons/tfi";

const paths = [
  {
    key: "dashboard",
    isDisplayed: true,
    subMenu: [],
    path: "/dashboard",
    subTitle: "welcome_message",
    isEditPage: false,
    component: React.lazy(() => import("../views/dashboard")),
    icon: <MdDashboard />,
  },
  {
    key: "appointments",
    isDisplayed: true,
    subMenu: [],
    path: "/appointments",
    subTitle: "appointments_subtitle",
    isEditPage: false,
    component: React.lazy(() => import("../views/appointments")),
    icon: <LiaBusinessTimeSolid />,
  },
  {
    key: "create-appointment",
    isDisplayed: false,
    subTitle: null,
    subMenu: [],
    path: "/appointments/create-appointment",
    isEditPage: false,
    component: React.lazy(() => import("../views/createAppointment")),
    icon: null,
  },
  {
    key: "edit-appointment",
    isDisplayed: false,
    subTitle: null,
    subMenu: [],
    path: "/appointments/edit-appointment/:id",
    isEditPage: true,
    component: React.lazy(() => import("../views/editAppointment")),
    icon: null,
  },
  {
    key: "clients",
    isDisplayed: true,
    subMenu: [],
    path: "/clients",
    subTitle: "clients_subtitle",
    icon: <LuFolderClock />,
    isEditPage: false,
    component: React.lazy(() => import("../views/clients")),
  },
  {
    key: "add-client",
    isDisplayed: false,
    subTitle: null,
    subMenu: [],
    path: "/clients/add-client",
    isEditPage: false,
    component: React.lazy(() => import("../views/addClient")),
    icon: null,
  },
  {
    key: "edit-client",
    isDisplayed: false,
    subTitle: null,
    subMenu: [],
    path: "/clients/edit-client/:id",
    subPaths: ["clients", "edit-client"],
    component: React.lazy(() => import("../views/editClient")),
    icon: null,
  },
  {
    key: "add-pet",
    subMenu: [],
    subTitle: null,
    isDisplayed: false,
    path: "/clients/edit-client/add-pet/:id",
    isEditPage: false,
    component: React.lazy(() => import("../views/addPet")),
    icon: null,
  },
  {
    key: "edit-pet",
    subMenu: [],
    subTitle: null,
    isDisplayed: false,
    path: "/clients/edit-client/edit-pet/:idClient/:idPet",
    subPaths: ["clients", "add-client", "add-pet"],
    component: React.lazy(() => import("../views/editPet")),
    icon: null,
  },
  {
    key: "reminders",
    isDisplayed: true,
    subMenu: [],
    icon: <HiOutlineChatAlt2 />,
    path: "/reminders",

    subTitle: "reminders_subtitle",
    isEditPage: false,
    component: React.lazy(() => import("../views/reminders")),
  },

  // {
  //   key: "hospitalisation",
  //   isDisplayed: true,
  //   subMenu: [],
  //   path: "/hospitalisation",
  //   subPaths: [],
  //   subTitle: "hospitalisation_subtitle",
  //   component: React.lazy(() => import("../views/hospitalisation")),
  //   icon: <CiHospital1 />,
  // },
  // {
  //   key: "cage_details",
  //   isDisplayed: false,
  //   subMenu: [],
  //   path: "/cage-details/:id",
  //   subPaths: [],
  //   subTitle: null,
  //   component: React.lazy(() => import("../views/cageDetails")),
  //   icon: <CiHospital1 />,
  // },
  // {
  //   key: "receipts",
  //   isDisplayed: true,
  //   subMenu: [],
  //   path: "/receipts",
  //   subPaths: [],
  //   subTitle: "receipts_subtitle",
  //   component: React.lazy(() => import("../views/receipts")),
  //   icon: <PiCalculatorLight />,
  // },

  {
    key: "settings",
    isDisplayed: true,
    icon: <IoSettingsOutline />,
    subMenu: [
      {
        key: "my-office",
        isDisplayed: true,
        path: "/settings/my-office",

        subTitle: null,
        isEditPage: false,
        component: React.lazy(() => import("../views/myOffice")),
        icon: <AiOutlineHome />,
      },
      // {
      //   key: "staff-account",
      //   isDisplayed: true,
      //   path: "/settings/staff-account",
      //   subPaths: [],
      //   subTitle: null,
      //   component: React.lazy(() => import("../views/staffAccount")),
      //   icon: <HiOutlineUsers />,
      // },
      {
        key: "products-and-prices",
        isDisplayed: true,
        path: "/settings/products-and-prices",

        subTitle: null,
        isEditPage: false,
        component: React.lazy(() => import("../views/productsAndPrices")),
        icon: <PiHandCoins />,
      },
      {
        key: "categories",
        isDisplayed: true,
        path: "/settings/categories",

        subTitle: null,
        isEditPage: false,
        component: React.lazy(() => import("../views/categories")),
        icon: <TfiMenuAlt />,
      },
    ],

    subTitle: "settings_subtitle",
  },
  {
    key: "settings",
    isDisplayed: false,
    path: "/settings/my-office",

    subMenu: [],
    subTitle: "settings_subtitle",
    isEditPage: false,
    component: React.lazy(() => import("../views/myOffice")),
    icon: <AiOutlineHome />,
  },
  // {
  //   key: "settings",
  //   isDisplayed: false,
  //   path: "/settings/staff-account",
  //   subPaths: [],
  //   subMenu: [],
  //   subTitle: "settings_subtitle",
  //   component: React.lazy(() => import("../views/staffAccount")),
  //   icon: <HiOutlineUsers />,
  // },
  {
    key: "settings",
    isDisplayed: false,
    path: "/settings/products-and-prices",

    subMenu: [],
    subTitle: "settings_subtitle",
    isEditPage: false,
    component: React.lazy(() => import("../views/productsAndPrices")),
    icon: <PiHandCoins />,
  },
  {
    key: "settings",
    isDisplayed: false,
    path: "/settings/categories",

    subMenu: [],
    subTitle: "settings_subtitle",
    isEditPage: false,
    component: React.lazy(() => import("../views/categories")),
    icon: <TfiMenuAlt />,
  },
  // {
  //   key: "edit-staff",
  //   isDisplayed: false,
  //   path: "/settings/edit-staff/:id",
  //   subPaths: [],
  //   subMenu: [],
  //   subTitle: "settings_subtitle",
  //   component: React.lazy(() => import("../views/editStaff")),
  //   icon: <TfiMenuAlt />,
  // },
];
const pathIcon = {
  dashboard: <MdDashboard />,
  appointments: <LiaBusinessTimeSolid />,
  clients: <LuFolderClock />,
  reminders: <HiOutlineChatAlt2 />,
  hospitalisation: <CiHospital1 />,
  receipts: <PiCalculatorLight />,
  settings: <IoSettingsOutline />,
  "my-office": <AiOutlineHome />,
  "staff-account": <HiOutlineUsers />,
  "products-and-prices": <PiHandCoins />,
  categories: <TfiMenuAlt />,
};

const unauthorizedPaths = ["/login", "/confirmPassword", "/forgotPassword"];

export { paths, unauthorizedPaths, pathIcon };
