import React, { Suspense, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import NotFound from "./NotFound";
import { useDispatch, useSelector } from "react-redux";
import { CheckAuth } from "../redux/Auth/action";
import { paths } from "../constants/paths";
import Sidebar from "components/Layout/sidebar";
import Loading from "components/loading";
import { GetAppointments } from "../redux/Appointments/action";
import { GetAllClients } from "../redux/Clients/actions";
import { GetClinic } from "../redux/Clinic/action";

const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.AuthReducer);
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    dispatch(CheckAuth(token, navigate));
    //eslint-disable-next-line
  }, [location.pathname, token]);

  useEffect(() => {
    dispatch(GetAppointments(token));
    dispatch(GetClinic());
    dispatch(GetAllClients(token));
  }, []);

  return (
    <div className="flex h-screen w-full bg-light-blue overflow-hidden">
      {/* Sidebar */}
      <Sidebar />
      {/* Main content area */}
      <div className="flex-1 flex flex-col relative rounded-3xl p-4 lg:p-8 overflow-hidden">
        <div className="flex-1 overflow-y-auto mt-4 lg:mt-0">
          <Suspense fallback={<Loading />}>
            <Routes>
              {paths.map((route) => {
                return route.subMenu.length !== 0 ? (
                  route.subMenu.map((subRoute) => (
                    <Route
                      key={subRoute.key}
                      path={subRoute.path}
                      element={<subRoute.component />}
                    />
                  ))
                ) : (
                  <Route
                    key={route.key}
                    path={route.path}
                    element={<route.component />}
                  />
                );
              })}
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </div>
      </div>

      {/* Overlay for sidebar on small screens */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30"
          onClick={() => setSidebarOpen(false)}
        ></div>
      )}
    </div>
  );
};

export default Layout;
