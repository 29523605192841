import React, { useState } from "react";
import PetooWhite from "assets/logos/logoPetooWhite.svg";
import { useDispatch, useSelector } from "react-redux";
import { LoginVet } from "../../redux/Auth/action";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import TestLogo from "assets/logos/testLogo.svg";

const Login2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState(null);
  const { loading, error } = useSelector((state) => state.AuthReducer);
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState("");

  const onSubmit = async () => {
    dispatch(LoginVet(values, navigate)).then((res) => {
      setMessage(res.message);
    });
  };
  console.log(loading);
  return (
    <div className="h-screen md:bg-light-blue xs:max-md:bg-white xs:max-md:justify-center xs:max-md:py-5 xs:max-md:flex md:py-20 md:px-10 xl:p-24">
      <div className="md:hidden flex flex-col justify-between w-full px-8">
        {/* image div */}
        <div className="flex justify-center">
          <img src={TestLogo} alt="test" className="xs:h-20 sm:h-32" />
        </div>
        {/* Inputs div */}
        <div className="flex flex-col gap-10">
          <div className="w-full">
            <label className={`mb-[10px] block text-black font-semibold`}>
              Email
            </label>
            <div className="relative">
              <input
                name="email"
                onChange={(e) =>
                  setValues({ ...values, email: e.target.value })
                }
                type="email"
                placeholder="info@yourmail.com"
                className="w-full h-12 bg-transparent rounded-md border border-stroke dark:border-dark-3 py-[10px] pr-3 pl-12 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2"
              />
              <span className="absolute top-1/2 left-4 -translate-y-1/2">
                <svg
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    opacity={0.8}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#9CA3AF"
                  >
                    <path d="M3.334 4.167A.838.838 0 0 0 2.501 5v10c0 .456.377.833.833.833h13.333a.838.838 0 0 0 .834-.833V5a.838.838 0 0 0-.834-.833H3.334ZM.834 5c0-1.377 1.123-2.5 2.5-2.5h13.333c1.377 0 2.5 1.123 2.5 2.5v10c0 1.377-1.123 2.5-2.5 2.5H3.334a2.505 2.505 0 0 1-2.5-2.5V5Z" />
                    <path d="M.985 4.522a.833.833 0 0 1 1.16-.205l7.856 5.499 7.855-5.5a.833.833 0 1 1 .956 1.366l-8.333 5.833a.833.833 0 0 1-.956 0L1.19 5.682a.833.833 0 0 1-.205-1.16Z" />
                  </g>
                </svg>
              </span>
            </div>
          </div>
          <div className="w-full">
            <label className={`mb-[10px] block text-black font-semibold `}>
              Mot de passe
            </label>
            <div className="relative">
              <input
                name="password"
                onChange={(e) =>
                  setValues({ ...values, password: e.target.value })
                }
                type={isVisible ? "text" : "password"}
                placeholder="*********"
                className={`h-12 w-full bg-transparent rounded-md border border-stroke px-5 text-dark-6 outline-none transition focus:border-primary  active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2`}
              />
              {/* Eye icon */}
              <div className="absolute inset-y-0 right-0 flex items-center pr-5 cursor-pointer">
                {isVisible ? (
                  <FaRegEyeSlash
                    size={20}
                    className=" cursor-pointer"
                    onClick={() => setIsVisible(!isVisible)}
                  />
                ) : (
                  <FaRegEye
                    size={20}
                    className=" cursor-pointer"
                    onClick={() => setIsVisible(!isVisible)}
                  />
                )}
              </div>
            </div>
          </div>
          <button
            onClick={onSubmit}
            disabled={loading ? true : false}
            className="bg-primary font-inter border-primary border rounded-md inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white hover:bg-[#1B44C8] hover:border-[#1B44C8] disabled:bg-gray-3 disabled:border-gray-3 disabled:text-dark-5 active:bg-[#1B44C8] active:border-[#1B44C8]"
          >
            {loading ? "Loading..." : "Se connecter"}
          </button>
        </div>
        {/* Error div */}
        {error ? (
          <div className="text-red-500  text-center">
            <span>{message}</span>
          </div>
        ) : (
          <div>&nbsp;</div>
        )}
        {/* Forgot password div */}
        <div className="text-center">
          <span className=" text-gray-500 cursor-pointer hover:text-petoo-blue mt-3">
            Mot de passe oublié?
          </span>
        </div>
      </div>
      <div className="xs:max-md:hidden flex flex-row justify-center h-full rounded-3xl bg-white xl:">
        <div className="w-1/2 parent flex flex-col justify-between md:justify-evenly py-5 items-center px-10 xl:py-10 md:overflow-y-auto">
          {/* Image div */}
          <div className="flex justify-center">
            <img src={TestLogo} alt="test" className="md:h-24 xl:h-36" />
          </div>
          {/* Inputs div */}
          <div className="flex flex-col gap-10 w-full xl:w-2/3">
            <div className="text-red-500  text-center xs:max-md:hidden">
              {error ? <span>{message}</span> : <div>&nbsp;</div>}
            </div>
            <div className="w-full">
              <label className={`mb-[10px] block text-black font-semibold`}>
                Email
              </label>
              <div className="relative">
                <input
                  name="email"
                  onChange={(e) =>
                    setValues({ ...values, email: e.target.value })
                  }
                  type="email"
                  placeholder="info@yourmail.com"
                  className="w-full h-12 bg-transparent rounded-md border border-stroke dark:border-dark-3 py-[10px] pr-3 pl-12 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2"
                />
                <span className="absolute top-1/2 left-4 -translate-y-1/2">
                  <svg
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      opacity={0.8}
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="#9CA3AF"
                    >
                      <path d="M3.334 4.167A.838.838 0 0 0 2.501 5v10c0 .456.377.833.833.833h13.333a.838.838 0 0 0 .834-.833V5a.838.838 0 0 0-.834-.833H3.334ZM.834 5c0-1.377 1.123-2.5 2.5-2.5h13.333c1.377 0 2.5 1.123 2.5 2.5v10c0 1.377-1.123 2.5-2.5 2.5H3.334a2.505 2.505 0 0 1-2.5-2.5V5Z" />
                      <path d="M.985 4.522a.833.833 0 0 1 1.16-.205l7.856 5.499 7.855-5.5a.833.833 0 1 1 .956 1.366l-8.333 5.833a.833.833 0 0 1-.956 0L1.19 5.682a.833.833 0 0 1-.205-1.16Z" />
                    </g>
                  </svg>
                </span>
              </div>
            </div>
            <div className="w-full">
              <label className={`mb-[10px] block text-black font-semibold `}>
                Mot de passe
              </label>
              <div className="relative">
                <input
                  name="password"
                  onChange={(e) =>
                    setValues({ ...values, password: e.target.value })
                  }
                  type={isVisible ? "text" : "password"}
                  placeholder="*********"
                  className={`h-12 w-full bg-transparent rounded-md border border-stroke px-5 text-dark-6 outline-none transition focus:border-primary  active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2`}
                />
                {/* Eye icon */}
                <div className="absolute inset-y-0 right-0 flex items-center pr-5 cursor-pointer">
                  {isVisible ? (
                    <FaRegEyeSlash
                      size={20}
                      className=" cursor-pointer"
                      onClick={() => setIsVisible(!isVisible)}
                    />
                  ) : (
                    <FaRegEye
                      size={20}
                      className=" cursor-pointer"
                      onClick={() => setIsVisible(!isVisible)}
                    />
                  )}
                </div>
              </div>
            </div>
            <button
              onClick={onSubmit}
              disabled={loading ? true : false}
              className="bg-primary font-inter border-primary border rounded-md inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white hover:bg-[#1B44C8] hover:border-[#1B44C8] disabled:bg-gray-3 disabled:border-gray-3 disabled:text-dark-5 active:bg-[#1B44C8] active:border-[#1B44C8]"
            >
              {loading ? "Loading..." : "Se connecter"}
            </button>
            <div className="xs:max-md:text-center xs:max-md:hidden">
              <span className=" text-gray-500 cursor-pointer hover:text-petoo-blue mt-3">
                Mot de passe oublié?
              </span>
            </div>
            <div className="text-red-500 text-center md:hidden">
              {error ? <span>{message}</span> : <div>&nbsp;</div>}
            </div>
          </div>
          {/* Forgot password div */}
          <div className="text-center md:hidden">
            <span className=" text-gray-500 cursor-pointer hover:text-petoo-blue mt-3">
              Mot de passe oublié?
            </span>
          </div>
        </div>
        <div className="w-1/2 bg-petoo-blue flex justify-center rounded-r-3xl">
          <img alt="petoo" src={PetooWhite} />
        </div>
      </div>
    </div>
  );
};

export default Login2;
