import React, { useState } from "react";
import PetooBlue from "assets/logos/petooBlue.svg";
import { paths } from "../../constants/paths";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ChangeLanguage } from "../../redux/Language/action";
import { Logout } from "../../redux/Auth/action";
import Dropdown from "../dropDown";
import { FaBars, FaTimes } from "react-icons/fa"; // Import icons for the toggle button

const languages = ["Fr", "En"];
const defaultStyle =
  "h-14 flex flex-row items-center text-main-black gap-4 pr-3 pl-3 rounded-lg border-2 border-white cursor-pointer";
const selectedPathStyle =
  "h-14 flex flex-row items-center text-petoo-blue gap-4 pr-3 pl-3 rounded-lg bg-light-blue border-blue border-2 cursor-pointer";

function Sidebar() {
  const [sidebarOpen, setSidebarOpen] = useState(false); // State to manage sidebar visibility
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { language, translation } = useSelector(
    (state) => state.LanguageReducer
  );

  const logout = () => {
    dispatch(Logout()).then((result) => {
      localStorage.removeItem("token");
      navigate("/login");
    });
  };

  const SwitchLanguage = (newLang) => {
    dispatch(ChangeLanguage(newLang));
  };

  return (
    <div className="flex h-screen relative">
      <div className="bg-blue  rounded-full absolute top-2 left-2 z-30">
        <button
          className="block lg:hidden p-2 text-white "
          onClick={() => setSidebarOpen(true)}
        >
          <FaBars size={24} />
        </button>
      </div>

      <div
        className={`fixed lg:static inset-y-0 left-0 transform ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0 transition-transform duration-300 ease-in-out bg-white text-black h-screen flex flex-col p-6 z-50`}
      >
        <div className="flex flex-col p-5 relative">
          <button
            className="block lg:hidden mb-4 text-blue absolute top-0 left-0"
            onClick={() => setSidebarOpen(false)}
          >
            <FaTimes size={24} />
          </button>
          <img
            alt="petoo"
            src={PetooBlue}
            width="200px"
            height="100px"
            className=""
          />
          <span className="text-petoo-blue text-xl font-sans font-semibold -mt-4 ml-2">
            {translation.vets[language]}
          </span>
        </div>
        <div className="flex flex-col justify-between h-full">
          <div className="h-full overflow-y-auto">
            <div className="flex gap-2 flex-col pt-8 border-t border-main-gray font-bold">
              {paths.map((route) => {
                if (route.isDisplayed) {
                  if (route.subMenu.length !== 0) {
                    return <Dropdown key={route.key} route={route} />;
                  }
                  return (
                    <Link
                      key={route.key}
                      to={route.path}
                      className={
                        location.pathname === route.path
                          ? selectedPathStyle
                          : defaultStyle
                      }
                      onClick={() => setSidebarOpen(false)} // Close sidebar on link click
                    >
                      <span className="text-xl">{route.icon}</span>
                      <span className="font-medium">
                        {translation[route.key][language]}
                      </span>
                    </Link>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
          <div className="flex flex-col justify-center gap-3">
            <div className="flex flex-row justify-center gap-2 text-white">
              {languages.map((lg) => (
                <div
                  key={lg}
                  onClick={() => SwitchLanguage(lg.toLowerCase())}
                  className={`px-2 py-1 font-medium cursor-pointer ${
                    lg.toLowerCase() === language
                      ? "bg-blue text-white rounded-lg"
                      : "bg-white text-black"
                  }`}
                >
                  {lg}
                </div>
              ))}
            </div>
            <button
              onClick={logout}
              className="border-blue border rounded-md py-2 px-5 text-center font-medium text-blue hover:bg-light-blue"
            >
              {translation["logout"][language]}
            </button>
          </div>
        </div>
      </div>
      {/* Overlay for sidebar on small screens */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={() => setSidebarOpen(false)}
        ></div>
      )}
    </div>
  );
}

export default Sidebar;
