import {
  LOADING_PET,
  FAIL,
  GET_ONE_PET,
  CREATE_PET,
  ARCHIVE_PET,
} from "../actionTypes";
// initialstate
const initialState = {
  allPets: [],
  onePet: {},
  error: false,
  message: "",
  loading: false,
};

// pure function=> (state, {type,payload})=>
const PetReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_PET:
      return { ...state, loading: true };
    case FAIL:
      return {
        ...state,
        error: true,
        loading: false,
        message: payload.message,
      };

    case GET_ONE_PET:
      return {
        ...state,
        error: false,
        loading: false,
        onePet: payload.data,
        message: payload.message,
      };
    case CREATE_PET:
      return {
        ...state,
        error: false,
        loading: false,
        message: payload.message,
      };
    case ARCHIVE_PET:
      return {
        ...state,
        error: false,
        loading: false,
        message: payload.message,
      };

    default:
      return state;
  }
};

export default PetReducer;
