import axios from "axios";
import { FAIL, LOADING_CLINIC, GET_CLINIC, EDIT_CLINIC } from "../actionTypes";

export const GetClinic = (token) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_CLINIC });
    const headers = {
      "x-petoo-token": localStorage.getItem("token"),
    };
    const res = await axios.get(
      "https://dev-backend.petoo.app/api/vet/get-clinic",
      {
        headers,
      }
    );
    const { data } = res;

    dispatch({
      type: GET_CLINIC,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: FAIL, payload: error.response.data });
  }
};

export const EditClinic = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_CLINIC });
    const headers = {
      "x-petoo-token": localStorage.getItem("token"),
    };
    const res = await axios.put(
      "https://dev-backend.petoo.app/api/vet/edit-cabine",
      values,
      { headers }
    );
    const { data } = res;
    dispatch({
      type: EDIT_CLINIC,
      payload: data,
    });
    dispatch(GetClinic());
    return data;
  } catch (error) {
    dispatch({ type: FAIL, payload: error.response.data });
  }
};
