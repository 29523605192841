import React, { useState } from "react";
import PetooWhite from "assets/logos/logoPetooWhite.svg";
import PetooBlue from "assets/logos/petooBlue.svg";
import { useDispatch, useSelector } from "react-redux";
import { LoginVet } from "../../redux/Auth/action";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState(null);
  const { loading, error, message } = useSelector((state) => state.AuthReducer);
  const [isVisible, setIsVisible] = useState(false);

  const onSubmit = async () => {
    dispatch(LoginVet(values, navigate));
  };

  return (
    <div className=" h-screen p-24 bg-light-blue">
      <div className="flex flex-row justify-center h-full rounded-3xl bg-white">
        <div className="w-full h-full parent flex justify-center  items-center">
          <div className="flex flex-col child w-1/2 gap-20 ">
            <div className="flex flex-row gap-1 items-center ">
              <img alt="petoo" src={PetooBlue} />
              <span className="text-petoo-blue text-2xl mt-10 font-sans font-semibold">
                Vétérinaires
              </span>
            </div>

            <div className="flex flex-col gap-4">
              {error ? (
                <div className="text-red-500  text-center">
                  <span>{message}</span>
                </div>
              ) : (
                <div className="text-black xs:text-red sm:text-blue md:text-green lg:text-pink xl:text-yellow">
                  &nbsp;
                </div>
              )}
              <div className="w-full">
                <label className={`mb-[10px] block text-black font-semibold`}>
                  Email
                </label>
                <div className="relative">
                  <input
                    name="email"
                    onChange={(e) =>
                      setValues({ ...values, email: e.target.value })
                    }
                    type="email"
                    placeholder="info@yourmail.com"
                    className="w-full h-12 bg-transparent rounded-md border border-stroke dark:border-dark-3 py-[10px] pr-3 pl-12 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2"
                  />
                  <span className="absolute top-1/2 left-4 -translate-y-1/2">
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        opacity={0.8}
                        fillRule="evenodd"
                        clipRule="evenodd"
                        fill="#9CA3AF"
                      >
                        <path d="M3.334 4.167A.838.838 0 0 0 2.501 5v10c0 .456.377.833.833.833h13.333a.838.838 0 0 0 .834-.833V5a.838.838 0 0 0-.834-.833H3.334ZM.834 5c0-1.377 1.123-2.5 2.5-2.5h13.333c1.377 0 2.5 1.123 2.5 2.5v10c0 1.377-1.123 2.5-2.5 2.5H3.334a2.505 2.505 0 0 1-2.5-2.5V5Z" />
                        <path d="M.985 4.522a.833.833 0 0 1 1.16-.205l7.856 5.499 7.855-5.5a.833.833 0 1 1 .956 1.366l-8.333 5.833a.833.833 0 0 1-.956 0L1.19 5.682a.833.833 0 0 1-.205-1.16Z" />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>

              <div className="w-full">
                <label className={`mb-[10px] block text-black font-semibold `}>
                  Mot de passe
                </label>
                <div className="relative">
                  <input
                    name="password"
                    onChange={(e) =>
                      setValues({ ...values, password: e.target.value })
                    }
                    type={isVisible ? "text" : "password"}
                    placeholder="*********"
                    className={`h-12 w-full bg-transparent rounded-md border border-stroke px-5 text-dark-6 outline-none transition focus:border-primary  active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2`}
                  />
                  {/* Eye icon */}
                  <div className="absolute inset-y-0 right-0 flex items-center pr-5 cursor-pointer">
                    {isVisible ? (
                      <FaRegEyeSlash
                        size={20}
                        className=" cursor-pointer"
                        onClick={() => setIsVisible(!isVisible)}
                      />
                    ) : (
                      <FaRegEye
                        size={20}
                        className=" cursor-pointer"
                        onClick={() => setIsVisible(!isVisible)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <button
                onClick={onSubmit}
                disabled={loading ? true : false}
                className="bg-primary font-inter border-primary border rounded-md inline-flex items-center justify-center py-3 px-7 text-center text-base font-medium text-white hover:bg-[#1B44C8] hover:border-[#1B44C8] disabled:bg-gray-3 disabled:border-gray-3 disabled:text-dark-5 active:bg-[#1B44C8] active:border-[#1B44C8]"
              >
                {loading ? "Loading..." : "Se connecter"}
              </button>
              <span className=" text-gray-500 cursor-pointer hover:text-petoo-blue mt-3">
                Mot de passe oublié?
              </span>
            </div>
          </div>
        </div>
        <div className="w-full h-full bg-petoo-blue flex justify-center rounded-r-3xl">
          <img alt="petoo" src={PetooWhite} />
        </div>
      </div>
    </div>
  );
};

export default Login;
