import axios from "axios";
import {
  LOADING_CLIENT,
  FAIL,
  GET_ALL_CLIENTS,
  GET_ONE_CLIENT,
  CREATE_CLIENT,
  EDIT_CLIENT,
  ARCHIVE_CLIENT,
} from "../actionTypes";

export const GetAllClients = (token) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_CLIENT });
    const headers = {
      "x-petoo-token": localStorage.getItem("token"),
    };
    const res = await axios.get(
      "https://dev-backend.petoo.app/api/vet/get-all-clients",
      {
        headers,
      }
    );
    const { data } = res;
    dispatch({
      type: GET_ALL_CLIENTS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: FAIL, payload: error.response.data });
  }
};

export const GetOneClient = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_CLIENT });
    const headers = {
      "x-petoo-token": localStorage.getItem("token"),
    };
    const res = await axios.get(
      "https://dev-backend.petoo.app/api/vet/get-one-client/" + id,
      { headers }
    );
    const { data } = res;
    dispatch({
      type: GET_ONE_CLIENT,
      payload: data,
    });
    return res.data;
  } catch (error) {
    dispatch({ type: FAIL, payload: error.response.data });
  }
};

export const CreateClient = (values, navigate) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_CLIENT });
    const headers = {
      "x-petoo-token": localStorage.getItem("token"),
    };
    const res = await axios.post(
      "https://dev-backend.petoo.app/api/vet/add-client",
      values,
      { headers }
    );
    const { data } = res.data;
    dispatch({
      type: CREATE_CLIENT,
      payload: data,
    });
    navigate(`/clients/edit-client/${data.id}`);
    dispatch(GetAllClients(localStorage.getItem("token")));
    return data;
  } catch (error) {
    dispatch({ type: FAIL, payload: error.response.data });
  }
};

export const UpdateClient = (id, values) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_CLIENT });
    const headers = {
      "x-petoo-token": localStorage.getItem("token"),
    };
    const res = await axios.put(
      "https://dev-backend.petoo.app/api/vet/edit-client/" + id,
      values,
      { headers }
    );
    const { data } = res;
    dispatch({
      type: EDIT_CLIENT,
      payload: data,
    });

    dispatch(GetAllClients());
    return data;
  } catch (error) {
    dispatch({ type: FAIL, payload: error.response.data });
  }
};

export const ArchiveClient = (id, values) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_CLIENT });
    const headers = {
      "x-petoo-token": localStorage.getItem("token"),
    };
    const res = await axios.put(
      "https://dev-backend.petoo.app/api/vet/archive-client/" + id,
      values,
      { headers }
    );
    const { data } = res;
    dispatch({
      type: ARCHIVE_CLIENT,
      payload: data,
    });

    dispatch(GetAllClients());
    return data;
  } catch (error) {
    dispatch({ type: FAIL, payload: error.response.data });
  }
};
