import { combineReducers } from "redux";
import LanguageReducer from "./Language/reducer";
import ViewReducer from "./views/reducer";
import AuthReducer from "./Auth/reducer";
import AppointmentReducer from "./Appointments/reducer";
import StaffReducer from "./Staff/reducer";
import PetReducer from "./Pet/reducer";
import ClientReducer from "./Clients/reducer";
import VetReducer from "./Vet/reducer";
import ClinicReducer from "./Clinic/reducer";

const reducers = combineReducers({
  ViewReducer,
  LanguageReducer,
  AuthReducer,
  AppointmentReducer,
  StaffReducer,
  PetReducer,
  ClientReducer,
  VetReducer,
  ClinicReducer,
});

export default reducers;
