import axios from "axios";
import {
  EDIT_APPOINTMENT,
  FAIL,
  GET_APPOINTMENT,
  GET_APPOINTMENTS,
  LOADING_APPOINTMENTS,
} from "../actionTypes";

export const GetAppointments = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_APPOINTMENTS });
    const headers = {
      "x-petoo-token": localStorage.getItem("token"),
    };
    const res = await axios.get(
      "https://dev-backend.petoo.app/api/vet/get-all-appointments",
      { headers }
    );
    const { data } = res;
    dispatch({
      type: GET_APPOINTMENTS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: FAIL, payload: error.response.data });
  }
};

export const GetAppointment = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_APPOINTMENTS });
    const headers = {
      "x-petoo-token": localStorage.getItem("token"),
    };
    const res = await axios.get(
      "https://dev-backend.petoo.app/api/vet/get-one-appointment/" + id,
      { headers }
    );
    const { data } = res;
    dispatch({
      type: GET_APPOINTMENT,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({ type: FAIL, payload: error.response.data });
    return error.response.data;
  }
};

export const BookAppointment = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_APPOINTMENTS });
    const headers = {
      "x-petoo-token": localStorage.getItem("token"),
    };
    const res = await axios.post(
      "https://dev-backend.petoo.app/api/vet/book-appointment",
      values,
      { headers }
    );
    const { data } = res;
    dispatch({
      type: GET_APPOINTMENT,
      payload: data,
    });
    dispatch(GetAppointments());
    return data;
  } catch (error) {
    dispatch({ type: FAIL, payload: error.response.data });
  }
};

export const UpdateAppointment = (id, values) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_APPOINTMENTS });
    const headers = {
      "x-petoo-token": localStorage.getItem("token"),
    };
    const res = await axios.put(
      "https://dev-backend.petoo.app/api/vet/edit-appointment/" + id,
      values,
      { headers }
    );
    const { data } = res;
    dispatch({
      type: EDIT_APPOINTMENT,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({ type: FAIL, payload: error.response.data });
  }
};

export const UpdateAppointmentStatus = (id, status) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_APPOINTMENTS });
    const headers = {
      "x-petoo-token": localStorage.getItem("token"),
    };
    const res = await axios.put(
      `https://dev-backend.petoo.app/api/vet/update-appointment-status/${id}/${status}`,
      "",
      { headers }
    );
    const { data } = res;
    dispatch({
      type: EDIT_APPOINTMENT,
      payload: data,
    });

    dispatch(GetAppointment(id));
    dispatch(GetAppointments());
    return data;
  } catch (error) {
    dispatch({ type: FAIL, payload: error.response.data });
  }
};
