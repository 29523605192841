import {
  LOADING_APPOINTMENTS,
  FAIL,
  GET_APPOINTMENTS,
  GET_APPOINTMENT,
  CREATE_APPOINTMENT,
  EDIT_APPOINTMENT,
} from "../actionTypes";
// initialstate
const initialState = {
  appointments: [],
  appointment: {},
  error: false,
  message: "",
  loading: false,
};

// pure function=> (state, {type,payload})=>
const AppointmentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_APPOINTMENTS:
      return { ...state, loading: true };
    case FAIL:
      return {
        ...state,
        error: true,
        loading: false,
        message: payload.message,
      };
    case GET_APPOINTMENTS:
      return {
        ...state,
        error: false,
        loading: false,
        appointments: payload.data,
      };
    case GET_APPOINTMENT:
      return {
        ...state,
        error: false,
        loading: false,
        appointment: payload.data,
        message: payload.message,
      };
    case CREATE_APPOINTMENT:
      return {
        ...state,
        error: false,
        loading: false,
        message: payload.message,
      };
    case EDIT_APPOINTMENT:
      return {
        ...state,
        error: false,
        loading: false,
        message: payload.message,
      };
    default:
      return state;
  }
};

export default AppointmentReducer;
