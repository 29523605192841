import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./views/login";
import ForgotPassword from "./views/forgotPassword";
import Layout from "./views/Layout";
import { unauthorizedPaths } from "./constants/paths";
import Loading from "components/loading";
import "./App.css";
import Login2 from "views/login2";

const App = () => {
  const token = localStorage.getItem("token");
  const path = window.location.pathname;
  useEffect(() => {
    if (token && unauthorizedPaths.includes(path)) {
      window.location.href = "/dashboard";
    }
    //eslint-disable-next-line
  }, []);
  return (
    <Router>
      <div>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/confirmPassword" element={<Login />} />
            <Route path="/login" element={<Login2 />} />
            <Route path="/*" element={<Layout />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
};

export default App;
